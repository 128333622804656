import {useCallback, useEffect, useMemo, useState} from "react";
import Loading from "../elements/Loading";
import getAPIClientMocked from "../hooks/apiClientMocked";
import InlineNotification from "../elements/InlineNotification";
import '../styles/pages/placement_audio.scss'
import getAPIClient from "../hooks/apiClient";
import moment from "moment";
import {ReactComponent as SelectAllIcon} from "../assets/select-all.svg";
import {ReactComponent as IncomingCallIcon} from "../assets/incoming-call.svg";
import {ReactComponent as OutgoingCallIcon} from "../assets/outgoing-call.svg";
import {ReactComponent as CalendarIcon} from "../assets/calendar.svg";
import {ReactComponent as VoiceIcon} from "../assets/voice.svg";
import {ReactComponent as CloudIcon} from "../assets/cloud.svg";


export default function CallsSelectorPage({BX24, sendRequestPath, buttonText, headerText}) {

    const api = useMemo(() => getAPIClient(BX24), [])

    const [isLoading, setIsLoading] = useState(false) // TODO
    const [notificationText, setNotificationText] = useState('')
    const [notificationType, setNotificationType] = useState('error')

    const handleResponseError = useCallback((default_text, e) => {
        console.log('error: ', e)
        setNotificationType('error')
        if (e?.response?.data?.detail) {
            setNotificationText(e.response.data.detail)
        } else {
            setNotificationText(default_text)
        }
    }, [])

    // ----- Списки аудио -----

    const [audioList, setAudioList] = useState([])
    const [selectedAudioIdsList, setSelectedAudioIdsList] = useState([])

    useEffect(() => {
        setIsLoading(true)
        let placement = BX24.placement.info();
        console.log('placement.options', placement.options)
        // let crmItemId = `91443`
        let crmItemId = `${placement.options.ID}`
        let crmType = 'lead'
        api.get('/transcription/calls', {
            params: {
                element_id: crmItemId,
                smart_process_type: crmType
            }
        }).then(r =>
            setAudioList(r.data)
        ).catch(e =>
            handleResponseError('Произошла неизвестная ошибка. Обратитесь пожалуйста в тех поддержку ВебФормат', e)
        ).finally(() => setIsLoading(false))
    }, [])

    const selectAudio = useCallback((id) => {
        if (selectedAudioIdsList.includes(id)) {
            let newList = []
            for (let i in selectedAudioIdsList){
                let audioId = selectedAudioIdsList[i]
                if (audioId !== id) newList.push(audioId)
            }
            setSelectedAudioIdsList(newList)
        } else {
            setSelectedAudioIdsList(prevState => [...prevState, id])
        }
    }, [selectedAudioIdsList, setSelectedAudioIdsList])

    const selectAll = useCallback(() => {
        if (selectedAudioIdsList.length === audioList.length) {
            setSelectedAudioIdsList([])
        } else {
            setSelectedAudioIdsList(audioList.map(el => el.id))
        }
    }, [selectedAudioIdsList, audioList])

    const startProcess = useCallback((id) => {
        setIsLoading(true)
        let placement = BX24.placement.info();
        let crmItemId = `${placement.options.ENTITY_ID}`
        let crmType = 'lead'
        api.post(sendRequestPath, {
            voximplant_ids: selectedAudioIdsList
        }).then(r => {
            BX24.closeApplication();
        }).catch(e =>
            handleResponseError('Произошла неизвестная ошибка. Обратитесь пожалуйста в тех поддержку ВебФормат', e)
        ).finally(() => setIsLoading(false))
    }, [selectedAudioIdsList])

    const fmtMSS = useCallback((s) => {
        return(s-(s%=60))/60+(9<s?':':':0')+s
    }, [])


    return <div className={'audio-page-main'}>
        <Loading isLoading={isLoading}/>
        <div className={'start-container'}>
            {(notificationText.length !== 0) && <InlineNotification
                className={'error-notification'}
                type={notificationType}
                text={notificationText}
                hideFlag={notificationText.length === 0}
            />}
            <div className={'container-header'}>
                {headerText}
            </div>
            {(audioList.length !== 0) && <div className={'select-all-row'}>
                <div
                    className={'select-all-button' + (selectedAudioIdsList.length === audioList.length ? ' select-checked' : '')}
                    onClick={selectAll}>
                    <div className={'select-all-icon'}><SelectAllIcon/></div>
                    <div
                        className={'select-all-text'}>{(selectedAudioIdsList.length !== audioList.length) ? 'Выбрать все записи' : 'Отменить выделение'}</div>
                </div>
            </div>}
            <div className={'audio-list'}>
                {audioList.map(audio => <div
                    key={`audio-${audio.id}`}
                    className={'audio' + (selectedAudioIdsList.includes(audio.id) ? ' audio-selected' : '')}
                    onClick={() => selectAudio(audio.id)}
                >
                    <div className={'audio-number'}>
                        <div className={'type-icon' + (audio.type === '1' ? ' outgoing-call' : ' incoming-call')}>
                            {(audio.type === '1') ? <OutgoingCallIcon/> : <IncomingCallIcon/>}
                        </div>
                        <div className={'number-text'}>
                            {audio.client_phone_number[0] === '7' ? '+' + audio.client_phone_number : audio.client_phone_number}
                        </div>
                    </div>
                    <hr/>
                    <div className={'call-type'}>
                        {(audio.type === '1') ? 'Исходящий' : 'Входящий'} звонок
                    </div>
                    <div className={'audio-date audio-data-row'}>
                        <div className={'row-icon'}><CalendarIcon/></div>
                        <div className={'row-text'}>
                            <span className={'audio-row-title'}>Дата:</span> {moment(audio.start_date).format('DD.MM HH:mm')}
                        </div>
                    </div>
                    <div className={'audio-duration audio-data-row'}>
                        <div className={'row-icon'}><VoiceIcon/></div>
                        <div className={'row-text'}>
                            <span className={'audio-row-title'}>Длительность:</span> {fmtMSS(audio.duration)}
                        </div>
                    </div>
                    <div className={'last-call-element'}>
                        <div className={'call-provider audio-data-row'}>
                            <div className={'row-text'}>
                                <span className={'audio-row-title'}>Провайдер:</span> {audio.rest_app_name}
                            </div>
                        </div>
                    </div>
                </div>)}
            </div>
            <div className={'interact-buttons' + (selectedAudioIdsList.length === 0 ? ' hide' : '')}>
                <div className={'selected-counter'}>
                    Выбрано: {selectedAudioIdsList.length}
                </div>
                <button onClick={startProcess}>
                    {buttonText}
                </button>
            </div>
        </div>
    </div>
}

import './styles/main.scss';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {useMemo, useState} from "react";
import Loading from "./elements/Loading";
import CallsSelectorPage from "./pages/CallsSelectorPage";

function App() {
    const [appInitialized, setAppInitialized] = useState(false)

    const BX24 = useMemo(() => {
        // Для страницы авторизации hh.ru вне битрикса
        if (
            window.location.pathname.includes('hh_authorize') ||
            window.location.pathname.includes('hh_chat_authorize')
        ){
            console.log('bx excluded')
            setAppInitialized(true)
            return
        }

        let bx = window.BX24
        bx.init(() => setAppInitialized(true));
        return bx;
    }, [])

    if (!appInitialized) {
        return <Loading isLoading={true}/>
    }
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/transcribe" element={<CallsSelectorPage
                    BX24={BX24}
                    sendRequestPath={'/transcription/transcribe'}
                    buttonText={'Транскрибировать'}
                    headerText={'Выберите звонки, которые хотите транскрибировать'}
                />} />
                <Route exact path="/resume" element={<CallsSelectorPage
                    BX24={BX24}
                    sendRequestPath={'/transcription/resume'}
                    buttonText={'Транскрибировать и резюмировать'}
                    headerText={'Выберите звонки, по которым хотите создать резюме'}
                />} />
                <Route exact path="/fields_filling" element={<CallsSelectorPage
                    BX24={BX24}
                    sendRequestPath={'/transcription/fields_filling'}
                    buttonText={'Заполнить поля CRM'}
                    headerText={'Выберите звонки, по которым хотите заполнить поля карточки CRM'}
                />} />
            </Routes>
        </BrowserRouter>
    )

}

export default App;
